export default function Style(){
  return (
    <style>
      {`
       .ob-logo {
    height: 45%;
    cursor: pointer;
}

.ob-footer {
    /* // position: fixed; */
    bottom: 0;
    width: 100%;
    margin: 15px 0;
    text-align: center;
    font-size: 14px;
}

.ob-navbar {
    padding: 25px 5%;
}

        `}
    </style>
  );
}
