import Styles from './styles.js';

import { Container, Content, Dropdown, Header, Nav, Navbar } from 'rsuite';
import { Link, Router } from 'utils/with-i18next';
import React from 'react';

import Footer from 'components/Footer';
import PropTypes from 'prop-types';
import SlackFeedback from 'components/SlackFeedback';
import AvatarComponent from 'components/Avatar';
import styled from '@emotion/styled';
// import 'styles/landingPagestyles.css';
const images = {
  listingBg: 'listing-bg-transparent.png',
  userProfileBg: 'listing-bg-transparent.png',
  listingStep1Bg: 'listing-step1-bg.png',
  listingStep2Bg: 'listing-step2-bg.png',
  listingStep3Bg: 'listing-step3-bg.png',
  listingStep4Bg: 'listing-step4-bg.png',
  listingStep5Bg: 'listing-step5-bg.png',
};

const LayoutBackground = styled('div')`
  background-image: ${props => (props.image ? `url('/static/images/${images[props.image]}')` : 'none')};
  background-position: right;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-attachment: fixed;
  sbackground-position-x: right;

  background-size: 40%;
  height: 100%;
`;

function OnboardingLayout({ children, image, handleLogout, currentUser }) {
  return (
    <>
      <main id="onboarding-main-container">
        <Container style={{ background: '#fafafa' }}>
          <Header>
            <Navbar className="ob-navbar">
              <Navbar.Header>
                <Link href={'/dashboard'}>
                  <img className="ob-logo" src="/static/images/BigLogo.png"></img>
                </Link>
              </Navbar.Header>
              <Navbar.Body>
                <Nav pullRight>
                  <Dropdown
                    placement="bottomEnd"
                    renderTitle={() => {
                      return currentUser?.displayName && <AvatarComponent size="md" name={currentUser?.displayName} />;
                    }}>
                    <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                      <p>Signed in as</p>
                      <strong>{currentUser?.displayName}</strong>
                    </Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item onSelect={() => Router.push('/dashboard')}>Go to Dashboard</Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item onSelect={() => Router.push('/dashboard/settings?tab=personal')}>
                      Account Settings
                    </Dropdown.Item>
                    <Dropdown.Item>Get Support</Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item onSelect={handleLogout}>Sign Out</Dropdown.Item>
                  </Dropdown>
                </Nav>
                {/* <Nav pullRight style={{ zIndex: 100 }}>
                  <Link href="/dashboard" passHref>
                    <a style={{ padding: '30px', lineHeight: '40px', textDecoration: 'none' }}>
                      <Icon icon="angle-double-left"></Icon> Go to Dashboard
                    </a>
                  </Link>
                </Nav> */}
              </Navbar.Body>
            </Navbar>
          </Header>
          <Content style={{ margin: '20px 5%', height: '100%' }}>
            <LayoutBackground className="animated fadeIn" image={image}>
              {children}
            </LayoutBackground>
          </Content>
          <Footer />
        </Container>
        <SlackFeedback user={currentUser}></SlackFeedback>
      </main>
      <Styles />
    </>
  );
}

OnboardingLayout.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.object,
  image: PropTypes.oneOf(Object.keys(images)),
};

export default OnboardingLayout;
