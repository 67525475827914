import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon } from 'rsuite';
import { getSourceUrl, generateRandomAvatar } from './generator';
import { useUser } from 'contexts/UserContext';

function AvatarComponent({ name, shape, mt, avatar, ...props }) {
  const { user: currentUser } = useUser();

  const getAltName = name => {
    return name
      .split(' ')
      .map(n => n[0])
      .splice(0, 2)
      .join('')
      .toUpperCase();
  };

  return (
    <Avatar
      {...props}
      src={
        // show the avatar if it exists (this is used to display avatars of other users)
        avatar && Object.keys(avatar).length > 0
          ? getSourceUrl(avatar)
          : // show the current user's avatar if it exists AND the name matches the current user's name
          Object.keys(currentUser?.avatar).length > 0 && name == currentUser?.displayName
          ? getSourceUrl(currentUser.avatar)
          : generateRandomAvatar(shape, name) // show a random avatar for users without an avatar
      }
      circle
      style={{ backgroundColor: '#fff', marginTop: mt ? mt : '-5px' }}>
      {name ? getAltName(name) : <Icon style={{ fontSize: '20px' }} icon="user" />}
    </Avatar>
  );
}

AvatarComponent.propTypes = {
  shape: PropTypes.oneOf(['Circle', 'Transparent']),
  name: PropTypes.string,
};

export default AvatarComponent;
